body {
  background: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

form {
  max-width: 680px;
  margin: 0 auto;
}

h2 {
  font-weight: 100;
  color: black;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}

.form {
  background: #0e101c;
  max-width: 400px;
  margin: 0 auto;
}

input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: black;
  font-size: 18px;
  font-weight: 200;
  word-break: break-all;
}

button[type="submit"],
input[type="submit"] {
  background:#dd6baa;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 16px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 10px;
  -webkit-font-smoothing: subpixel-antialiased;
}

button[type="submit"]:hover,
input[type="submit"]:hover {
  background: #b13b7c;
}

button[type="submit"]:active,
input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

button[type="submit"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}

.App {
  max-width: 700px;
  margin: 0 auto;
  padding: 1em 0.5em 3em 0.5em;
}

button[type="button"] {
  display: block;
  appearance: none;
  background: #333;
  color: white;
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

hr {
  margin-top: 30px;
}

button {
  display: block;
  appearance: none;
  margin-top: 40px;
  border: 1px solid #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

.question {
  margin-right: 20px;
  font-size: 26px;
  font-weight: 100;
}

.alert-error {
  margin-top: 20px;
}

.hissu {
  color: red;
}

div.central {
  height: 40vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

span {
  font-size: 16px;
  font-weight: 200;
  word-break: break-all;
}

.side-mage {
  margin: 0 1em;
}

.top-line {
  margin-top: 2em;
  border-top: 0.2px solid rgb(196, 189, 189);
}